import React from "react";

const AboutCompany = () => {
  return (
    <>
      <div className="about-details-page pt-140 pb-110">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="about-details-info pr-65">
                <div className="tp-section-wrapper">
                  <span className="tp-section-subtitle mb-25">
                    About our Company
                  </span>
                  <h2 className="tp-pt-size mb-40">
                  Building a Better Workforce, Together
                  </h2>
                </div>
                <div className="about-tb-content">
                  <nav>
                    <div className="nav mb-30" id="nav-tab" role="tablist">
                      <button
                        className="nav-links active"
                        id="mission"
                        data-bs-toggle="tab"
                        data-bs-target="#nav-mission"
                        type="button"
                        role="tab"
                        aria-controls="nav-mission"
                        aria-selected="true"
                      >
                        Our Mission
                      </button>

                      <button
                        className="nav-links"
                        id="nav-vission-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#nav-vission"
                        type="button"
                        role="tab"
                        aria-controls="nav-vission"
                        aria-selected="false"
                      >
                        Our Vission
                      </button>
                      <button
                        className="nav-links"
                        id="nav-value-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#nav-value"
                        type="button"
                        role="tab"
                        aria-controls="nav-value"
                        aria-selected="false"
                      >
                        Our Value
                      </button>
                      <button
                        className="nav-links"
                        id="nav-people-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#nav-people"
                        type="button"
                        role="tab"
                        aria-controls="nav-people"
                        aria-selected="false"
                      >
                        Our People
                      </button>
                    </div>
                  </nav>
                  <div className="tab-content" id="nav-tabContent">
                    <div
                      className="tab-pane fade show active"
                      id="nav-mission"
                      role="tabpanel"
                      aria-labelledby="nav-mission"
                    >
                      <p>
                      Our mission is to provide a human resource services beneficial for both the employer and employee. We strive hard to become a premier national corporate manpower recruitment company with our reliability, honesty and commitment for better results.
 
                      </p>

                    </div>
                    <div
                      className="tab-pane fade"
                      id="nav-vission"
                      role="tabpanel"
                      aria-labelledby="nav-vission-tab"
                    >
                      <p>
                      Our vision is to lead the creation and delivery of innovative workforce solutions and services that enable our clients to win
the changing world of business and competition
                      </p>

                     
                    </div>
                    <div
                      className="tab-pane fade"
                      id="nav-value"
                      role="tabpanel"
                      aria-labelledby="nav-value-tab"
                    >
                      <p>
                      Intex Logistics pledges to deliver the best value in the market, offering a quality service delivery at
a competitive and affordable market price. As a total solutions provider, our services are tailored according to the unique requirements of each client. With us as your strategic partner, you will no longer have to worry about possible mismatch of quality and expectations among the workers. We also strive to address any issues related to the welfare of the workers supplied to our clients, alleviating their concerns on business operation interruption.
                      </p>

                      
                    </div>
                    <div
                      className="tab-pane fade"
                      id="nav-people"
                      role="tabpanel"
                      aria-labelledby="nav-people-tab"
                    >
                      <p>
                      We respect and care people as individuals and role of work in their lives by supporting them and enabling them to achieve their aims in work and in life. We recognize everyone's contribution to our success – our staff, our clients and our candidates.
                      </p>

                      
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 text-end">
              <div className="tp-about-img-box p-relative d-inline-block mb-30">
                <div className="about-page-img">
                  <img src="assets/img/about/about-page.png" alt="" />
                </div>
                <div className="dots-img">
                  <img src="assets/img/about/dot.jpg" alt="" />
                </div>
                <div className="about-info-box d-flex flex-column justify-content-center text-center">
                  <h3 className="box-title">7+</h3>
                  <h4 className="box-subtitle">
                    Years of <br />
                    Experience
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutCompany;
