import React from 'react';
import { Link } from 'react-router-dom';

const FooterThree = () => {
  return (
    <footer>
      <div className="tp-footer__area black-bg">
        <div className="tp-footer">
          {/* <!-- main-footer start  --> */}
          <div className="tp-footer__main">
            <div className="container">
              <div className="tp-footer-border pt-100 pb-70">
                <div className="pb-30 pb-70">
                  <div className="row">
                    <div className="col-12 col-md-6">
                      <div className="footer-logo mb-30">
                        <Link to="/" href="#"><img src="/assets/img/logo/logo.png" alt="" /></Link>
                      </div>
                    </div>
                    <div className="col-12 col-md-6 text-end">
                      <div className="footer-form-2 mb-30">
                        <form>
                          <input type="email" placeholder="Enter your mail" />
                          <button type="submit">Subscribe <i
                            className="fal fa-paper-plane"></i></button>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-3 col-md-6">
                    <div className="tp-footer__widget tp-footer__2 pb-30">
                      <h3 className="tp-footer__widget-title text-white">Our Locations</h3>
                      <ul>
                        <li><a href="#">Vadodra</a></li>
                        <li><a href="#">Faridabad</a></li>
                        <li><a href="#">Cochin</a></li>
                        <li><a href="#">Kolkata</a></li>
                        <li><a href="#">Nasik</a></li>
                        <li><a href="#">Jaipur</a></li>
                        <li><a href="#">Ujjain</a></li>
                        <li><a href="#">Hyderabad</a></li>
                        <li><a href="#">Bhubaneshwar</a></li>
                        <li><a href="#">Vijawada</a></li>
                        <li><a href="#">Chennai</a></li>
                        <li><a href="#">Hyderabad</a></li>
                        <li><a href="#">Bhubaneshwar</a></li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6">
                    <div className="tp-footer__widget tp-footer__2 pb-30">
                      <h3 className="tp-footer__widget-title text-white">Our Services</h3>
                      <ul>
                        <li><a href="#">Permanent Recruitment</a></li>
                        <li><a href="#">Temporary Staffing</a></li>
                        <li><a href="#">Contract Recruitment</a></li>
                        <li><a href="#">Employee Assessment</a></li>
                        <li><a href="#">Training & Development</a></li>
                        <li><a href="#">Career Transition</a></li>
                        <li><a href="#">Organizational Consulting</a></li>
                        <li><a href="#">Professional Financial Services</a></li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 ">
                    <div className="tp-footer__widget tp-footer__2  pb-30">
                      <h3 className="tp-footer__widget-title text-white">Quick Links</h3>
                      <ul>
                      <h3 className="tp-footer__widget-title">Industries we serve</h3>
                        <ul>
                          <li>
                            <a href="#">Automobile</a>
                          </li>
                          <li>
                            <a href="#">Engineering</a>
                          </li>
                          <li>
                            <a href="#">Manufacturing</a>
                          </li>
                          <li>
                            <a href="#">Retail</a>
                          </li>
                          <li>
                            <a href="#">Electronics</a>
                          </li>
                          <li>
                            <a href="#">Textiles</a>
                          </li>
                          <li>
                            <a href="#">E-Commerce</a>
                          </li>
                          <li>
                            <a href="#">Telecom</a>
                          </li>
                          <li>
                            <a href="#">Third-Party Logistics</a>
                          </li>
                          <li>
                            <a href="#">Hospitality</a>
                          </li>
                          <li>
                            <a href="#">Construction</a>
                          </li>
                          <li>
                            <a href="#">Government Projects</a>
                          </li>
                        </ul>
                      </ul>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6">
                    <div className="tp-footer__widget tp-footer__2  pb-30">
                      {/* <h3 className="tp-footer__widget-title text-white">Quick Links</h3> */}
                      <ul>
                        <li><a href="#"><b>203, Banarasi Heritage, Off. Link Road, Mindspace, Malad (W), Mumbai-400064</b>
</a></li>
                        <li><a href="tel:+88015569569365">1800 22 4689</a></li>
                        <li><a href="mailto:                            info@intexlogistics.in
">                            info@intexlogistics.in
</a></li>
                        <li><span> Office Hours: 9AM - 4PM</span></li>
                        <li><span> Sunday Off</span></li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- main footer end  --> */}
        </div>
      </div>

      {/* <!-- footer copy right --> */}
      <div className="top-footer-copyright pt-30 pb-30 black-bg">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="tp-copyrigh-text text-center text-white">
                <span>© {new Date().getFullYear()} Intex Logistics . All Rights Reserved..</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- footer copyright end  --> */}
    </footer>
  );
};

export default FooterThree;