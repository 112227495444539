import React from "react";
import { Link } from "react-router-dom";

const LocationMap2 = () => {
  return (
    <>
      <div className="tp-footer__area ">
        <div className="tp-footer">
          {/* <!-- footer top --> */}
          <div className="tp-footer__top pt-50 pb-50">
            <div className="container">
              <div className="row align-items-center">
                
                <div className="col-12 col-md-6">
                  <h2 className="tp-section-title ">
                    Intex Logistics <br/>India Pvt. Ltd. 
                  </h2>
                  <br/>
                  <h4>
                  Registered & Corporate Office:
                  </h4>
                  <p><b>203, Banarasi Heritage, Off. Link Road, Mindspace, Mindspace, <br/>Malad (W), Mumbai-400064<br/><br/>
Toll Free: 1800 22 4689 <br/>http://www.intexlogistics.in <br/>E-mail: info@intexlogistics.in</b>
                  </p>
                  <br/>
                  <h4>
                  Branch Offices:
                  </h4>
                  <p>
                    
<b className="tp-address-text">Vadodara:</b> F-101 , Natraj Enclave (TR T-2A), Jalaram Mandir Road,
<br/>Karelibaug, Vadodara - 390018 •• Mobile No: +91. 97143 23330.
<br/><br/>
<b>Faridabad:</b> 2211 , Sainik Colony, Sector 49, Faridabad,<br/> Haryana
121001 •• Mobile  +91 730 45 89876.
<br/><br/>
Other Offices in: <b>Bhubaneshwar, Kolkata, Cochin, Chennai, Nasik,
Jaipur, Ujjain, Hyderabad, Vijawada</b>

                  </p>

                  
                </div>
                <div className="col-lg-5 pl-50">
                  <div className="footer-logo ">
                    <Link to="/" href="#">
                      <img src="/assets/img/promotion/promotion2.jpg" alt="" />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- footer top end  --> */}
        </div>
      </div>
    </>
  );
};

export default LocationMap2;
