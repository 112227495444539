import React from "react";
import { Link } from "react-router-dom";
import { Link as ScrollLink } from 'react-scroll';


const Menus = () => {
  return (
    <ul>
      {/* <li className="">
        <ScrollLink to="intexlogistics.in" smooth={true} duration={500}>Home</ScrollLink>
        
      </li> */}
      <li>
        <ScrollLink to="aboutxx" smooth={true} duration={500}>About us</ScrollLink>
      </li>
      <li className="">
        <ScrollLink to="services" smooth={true} duration={500}>Services</ScrollLink>
        {/* <ul className="submenu">
          <li>
            <Link to="/team">Team</Link>
          </li>
          <li>
            <Link to="/team-details">Team details</Link>
          </li>
          <li>
            <Link to="/service">Service</Link>
          </li>
          <li>
            <Link to="/service-details">service details</Link>
          </li>
          <li>
            <Link to="/price">Price</Link>
          </li>
          <li>
            <Link to="/contact">contact</Link>
          </li>
          <li>
            <Link to="/faq">Faq</Link>
          </li>
        </ul> */}
      </li>
      <li className="">
        <ScrollLink to="brandsx" smooth={true} duration={500}>Testimonials</ScrollLink>
        {/* <ul className="submenu">
          <li>
            <Link to="/portfolio">Portfolio</Link>
          </li>
          <li>
            <Link to="/portfolio-details">Portfolio Details</Link>
          </li>
        </ul> */}
      </li>
      <li className="">
        <ScrollLink to="locations" smooth={true} duration={500}>Contact</ScrollLink>
        {/* <ul className="submenu">
          <li>
            <Link to="/blog">blog</Link>
          </li>
          <li>
            <Link to="/blog-details">blog Details</Link>
          </li>
        </ul> */}
      </li>
    </ul>
  );
};

export default Menus;
