import React from "react";
import { Link } from "react-router-dom";

const TopCategoryHomePageTwo = () => {
  return (
    <>
      <div className="tp-cta-area-2 grey-bg-4">
        <div className="container-fluid p-0">
          <div className="row g-0">
            <div className="col-lg-12 col-xl-4">
              <div className="tp-cta-2-left black-bg pt-135 pr-30 pl-90 pb-135">
                <div className="tp-section-wrapper">
                  <span className="tp-section-subtitle mb-20">
                  About Intex Logistics
                  </span>
                  <h2 className="tp-section-title text-white tp-title-size">
                  Building a Better Workforce, Together
                  </h2>
                  <p>
                  Intex Logistics India Pvt. Ltd. has been a trusted partner in workforce solutions since 1998. With a steadfast commitment to excellence, we specialize in delivering tailored human resource services across a diverse range of industries. Our mission is to empower businesses by providing top-tier recruitment, staffing, training, and consulting services. At Intex Logistics, we believe in building strong, long-lasting partnerships with our clients, ensuring their workforce needs are met with precision and care. 
                  </p>
                  <p className="pt-10">
                  Our dedicated team of experts works diligently to understand the unique challenges faced by each client, offering customized solutions that drive success and growth
                  </p>
                  <div className="mt-50">
                    <Link to="/service" className="tp-btn-white">
                      View More
                      <span>
                        <svg
                          width="22"
                          height="8"
                          viewBox="0 0 22 8"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M21.3536 4.35356C21.5488 4.15829 21.5488 3.84171 21.3536 3.64645L18.1716 0.464468C17.9763 0.269205 17.6597 0.269205 17.4645 0.464468C17.2692 0.65973 17.2692 0.976312 17.4645 1.17157L20.2929 4L17.4645 6.82843C17.2692 7.02369 17.2692 7.34027 17.4645 7.53554C17.6597 7.7308 17.9763 7.7308 18.1716 7.53554L21.3536 4.35356ZM-4.37114e-08 4.5L21 4.5L21 3.5L4.37114e-08 3.5L-4.37114e-08 4.5Z"
                            fill="currentColor"
                          />
                        </svg>
                        <svg
                          width="22"
                          height="8"
                          viewBox="0 0 22 8"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M21.3536 4.35356C21.5488 4.15829 21.5488 3.84171 21.3536 3.64645L18.1716 0.464468C17.9763 0.269205 17.6597 0.269205 17.4645 0.464468C17.2692 0.65973 17.2692 0.976312 17.4645 1.17157L20.2929 4L17.4645 6.82843C17.2692 7.02369 17.2692 7.34027 17.4645 7.53554C17.6597 7.7308 17.9763 7.7308 18.1716 7.53554L21.3536 4.35356ZM-4.37114e-08 4.5L21 4.5L21 3.5L4.37114e-08 3.5L-4.37114e-08 4.5Z"
                            fill="currentColor"
                          />
                        </svg>
                      </span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-xl-4">
              <div className="tp-cta-img w-img">
                <img src="assets/img/cta/cta.jpg" alt="" />
              </div>
            </div>
            <div className="col-lg-6 col-xl-4">
              <div className="tp-cta-2-right white-bg pt-135 pr-30 pl-75 pb-135">
                <div className="tp-section-wrapper">
                  <span className="tp-section-subtitle mb-20">
                    we increase your business
                  </span>
                  <h2 className="tp-section-title tp-title-size">
                    Motivate and increase daily productivity
                  </h2>
                  <p>
                    Tellus rutrum tellus pellentesque eu. Sagittis purus sit
                    amet volutpat. Sed ullamcorper tellus rutrum tellus
                    pellentesque eu. Sagittis purus sit amet volutpat. Sed
                    ullamcorper retherlly.
                  </p>
                  <p className="pt-10">
                    Tellus rutrum tellus pellentesque eu. Sagittis purus sit
                    amet volutpat. Sed ullamcorper tellus rutrum tellus
                    pellentesque eu. Sagittis purus sit
                  </p>
                  <div className="mt-50">
                    <Link to="/service" className="tp-btn-border">
                      View More
                      <span>
                        <svg
                          width="22"
                          height="8"
                          viewBox="0 0 22 8"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M21.3536 4.35356C21.5488 4.15829 21.5488 3.84171 21.3536 3.64645L18.1716 0.464468C17.9763 0.269205 17.6597 0.269205 17.4645 0.464468C17.2692 0.65973 17.2692 0.976312 17.4645 1.17157L20.2929 4L17.4645 6.82843C17.2692 7.02369 17.2692 7.34027 17.4645 7.53554C17.6597 7.7308 17.9763 7.7308 18.1716 7.53554L21.3536 4.35356ZM-4.37114e-08 4.5L21 4.5L21 3.5L4.37114e-08 3.5L-4.37114e-08 4.5Z"
                            fill="currentColor"
                          />
                        </svg>
                        <svg
                          width="22"
                          height="8"
                          viewBox="0 0 22 8"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M21.3536 4.35356C21.5488 4.15829 21.5488 3.84171 21.3536 3.64645L18.1716 0.464468C17.9763 0.269205 17.6597 0.269205 17.4645 0.464468C17.2692 0.65973 17.2692 0.976312 17.4645 1.17157L20.2929 4L17.4645 6.82843C17.2692 7.02369 17.2692 7.34027 17.4645 7.53554C17.6597 7.7308 17.9763 7.7308 18.1716 7.53554L21.3536 4.35356ZM-4.37114e-08 4.5L21 4.5L21 3.5L4.37114e-08 3.5L-4.37114e-08 4.5Z"
                            fill="currentColor"
                          />
                        </svg>
                      </span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TopCategoryHomePageTwo;
