import React from "react";

const Footer = () => {
  return (
    <>
      {/* <!-- footer area start --> */}
      <footer>
        <div className="tp-footer__area black-bg">
          <div className="tp-footer">
            {/* <!-- main-footer start  --> */}
            <div className="tp-footer__main">
              <div className="container">
                <div className="tp-footer-border pt-60 pb-30">
                  <div className="row">
                    <div className="col-lg-3 col-md-6">
                      <div className="tp-footer__widget  pb-30">
                        <h3 className="tp-footer__widget-title">
                          Our Locations
                        </h3>
                        <ul>
                          <li>
                            <a href="#">Vadodra</a>
                          </li>
                          <li>
                            <a href="#">Faridabad</a>
                          </li>
                          <li>
                            <a href="#">Kolkata</a>
                          </li>
                          <li>
                            <a href="#">Cochin</a>
                          </li>
                          <li>
                            <a href="#">Nasik</a>
                          </li>
                          <li>
                            <a href="#">Jaipur</a>
                          </li>
                          <li>
                            <a href="#">Ujjain</a>
                          </li>
                          <li>
                            <a href="#">Hyderabad</a>
                          </li>
                          <li>
                            <a href="#">Bhubaneshwar</a>
                          </li>
                          <li>
                            <a href="#">Vijawada</a>
                          </li>
                          <li>
                            <a href="#">Chennai</a>
                          </li>
                          
                        </ul>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                      <div className="tp-footer__widget pb-30">
                        <h3 className="tp-footer__widget-title">Our Services</h3>
                        <ul>
                          <li>
                            <a href="#">Permanent Recruitment</a>
                          </li>
                          <li>
                            <a href="#">Temporary Staffing</a>
                          </li>
                          <li>
                            <a href="#">Contract Recruitment</a>
                          </li>
                          <li>
                            <a href="#">Employee Assessment</a>
                          </li>
                          <li>
                            <a href="#">Training & Development</a>
                          </li>
                          <li>
                            <a href="#">Career Transition</a>
                          </li>
                          <li>
                            <a href="#">Organizational Consulting</a>
                          </li>
                          <li>
                            <a href="#">Professional Financial Services</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-6 ">
                      <div className="tp-footer__widget  pb-30">
                        <h3 className="tp-footer__widget-title">Industries we serve</h3>
                        <ul>
                          <li>
                            <a href="#">Automobile</a>
                          </li>
                          <li>
                            <a href="#">Engineering</a>
                          </li>
                          <li>
                            <a href="#">Manufacturing</a>
                          </li>
                          <li>
                            <a href="#">Retail</a>
                          </li>
                          <li>
                            <a href="#">Electronics</a>
                          </li>
                          <li>
                            <a href="#">Textiles</a>
                          </li>
                          <li>
                            <a href="#">E-Commerce</a>
                          </li>
                          <li>
                            <a href="#">Telecom</a>
                          </li>
                          <li>
                            <a href="#">Third-Party Logistics</a>
                          </li>
                          <li>
                            <a href="#">Hospitality</a>
                          </li>
                          <li>
                            <a href="#">Construction</a>
                          </li>
                          <li>
                            <a href="#">Government Projects</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                      <div className="tp-footer__widget  pb-30">
                        <h3 className="tp-footer__widget-title">Quick Links</h3>
                        <ul>
                          <li>
                            <a href="#">
                            Registered & Corporate Office: <b>203, Banarasi Heritage, Off. Link Road, Mindspace, Malad (W), Mumbai-400064</b>
                            </a>
                          </li>
                          <li>
                            <a href="tel:+88015569569365">1800 22 4689</a>
                          </li>
                          <li>
                            <a href="mailto:info@intexlogistics.in">
                            info@intexlogistics.in
                            </a>
                          </li>
                          <li>
                            <span> Office Hours: 9AM - 4PM</span>
                          </li>
                          <li>
                            {/* <span>  Day</span> */}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- main footer end  --> */}
          </div>
        </div>

        {/* <!-- footer copy right --> */}
        <div className="top-footer-copyright pt-30 black-bg">
          <div className="container">
            <div className="row">
              <div className="col-md-8 col-12">
                <div className="tp-copyrigh-text mb-30">
                  <span>
                    © {new Date().getFullYear()} Intex Logistics . All Rights Reserved.
                  </span>
                </div>
              </div>
              <div className="col-md-4 col-12">
                <div className="tp-footer-social-icon mb-30 text-md-end">
                  {/* <ul>
                    <li>
                      <a href="#">
                        <i className="fab fa-facebook"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fab fa-twitter"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fab fa-instagram"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fab fa-youtube"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fab fa-linkedin"></i>
                      </a>
                    </li>
                  </ul> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- footer copyright end  --> */}
      </footer>
      {/* <!-- footer area end --> */}
    </>
  );
};

export default Footer;
