import React from "react";
import Footer from "../common/Footer/Footer";
import Menu from "../common/Menu/Menu";
import Gallery from "./Gallery";
import Team from "../common/Team/Team";
import Feature from "./Feature";
import Testimonial from "../common/Testimonial/Testimonial";
import Brands from "../common/Brands/Brands";
import Brandstwo from "../common/Brands/Brandstwo";
import Brandsthree from "../common/Brands/Brandsthree";
import BlogThree from "../HomeThree/BlogThree";

import LastedBlog from "./LastedBlog";
import FullScreenBtn from "../common/FullScreenBtn";
import LocationMap from "../common/LocationMap.js";
import LocationMap2 from "../common/LocationMap2.js";

import TopAbout from "./TopAbout";
import TopCtaArea from "./TopCtaArea";
import TopFooter from "../common/TopFooter/TopFooter";
import HomeSlider from "./HomeSlider";
import OurService from "../Pages/Service/OurService";
import About from "../Pages/About/AboutCompany";
import Promotion from "../HomeThree/Promotion";
import { Element } from 'react-scroll';
import ContactUs from '../Pages/ContactUs/ContactInfo.js';



const Home = () => {
  return (
    <>
        <Menu />
      <HomeSlider />
      {/* <TopAbout /> */}
      <Element name="aboutxx">
        <About/>
      </Element>
      <Element name="services">
        <OurService/>
      </Element>
      <Element name="brandsx">
      <TopCtaArea />

      </Element>
      <Element name="brands">
      {/* <FullScreenBtn /> */}

        {/* <Brands />
        <Brandstwo />
        <Brandsthree /> */}
      </Element>
      <Element name="testimonials">
        <Testimonial id='example-destination' />
      </Element>
      {/* <Gallery /> */}
      {/* <Team /> */}
      {/* <Feature /> */}
      {/* <BlogThree /> */}
        <LocationMap />
              <Element name="locations">

        <LocationMap2 />
      </Element>
      {/* <Promotion /> */}
      {/* <LastedBlog /> */}
      <ContactUs/>

      <TopFooter />


      <Element name="footerx">
        <Footer />

      </Element>

      
    </>
  );
};

export default Home;
