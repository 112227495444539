import React from "react";

const ContactInfo = () => {
  return (
    <>
      <div className="contact-from-section pt-140">
        <div className="container">
          
          <div className="row">
            <div className="col-12">
              <div className="tp-ct-form white-bg pl-50 pr-50 pt-50 pb-50">
                <h3 className="pb-20">Get in touch with Intex Logistics!</h3>
                <form id="contact-form" action="/home" method="POST">
                  <input
                    type="text"
                    name="name"
                    placeholder="Enter your name*"
                    required
                  />
                  <input
                    type="email"
                    name="email"
                    placeholder="Your email here"
                    required
                  />
                  <input
                    type="text"
                    name="phone"
                    placeholder="Enter your number*"
                    required
                  />
                  <input
                    type="text"
                    name="subject"
                    placeholder="Subject*"
                    required
                  />
                  <textarea
                    name="message"
                    placeholder="Enter your Message*"
                  ></textarea>
                  <div className="text-center">
                    <button type="submit" className="tp-btn-border">
                      Send Message{" "}
                      <span>
                        <svg
                          width="22"
                          height="8"
                          viewBox="0 0 22 8"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M21.3536 4.35356C21.5488 4.15829 21.5488 3.84171 21.3536 3.64645L18.1716 0.464468C17.9763 0.269205 17.6597 0.269205 17.4645 0.464468C17.2692 0.65973 17.2692 0.976312 17.4645 1.17157L20.2929 4L17.4645 6.82843C17.2692 7.02369 17.2692 7.34027 17.4645 7.53554C17.6597 7.7308 17.9763 7.7308 18.1716 7.53554L21.3536 4.35356ZM-4.37114e-08 4.5L21 4.5L21 3.5L4.37114e-08 3.5L-4.37114e-08 4.5Z"
                            fill="currentColor"
                          ></path>
                        </svg>
                        <svg
                          width="22"
                          height="8"
                          viewBox="0 0 22 8"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M21.3536 4.35356C21.5488 4.15829 21.5488 3.84171 21.3536 3.64645L18.1716 0.464468C17.9763 0.269205 17.6597 0.269205 17.4645 0.464468C17.2692 0.65973 17.2692 0.976312 17.4645 1.17157L20.2929 4L17.4645 6.82843C17.2692 7.02369 17.2692 7.34027 17.4645 7.53554C17.6597 7.7308 17.9763 7.7308 18.1716 7.53554L21.3536 4.35356ZM-4.37114e-08 4.5L21 4.5L21 3.5L4.37114e-08 3.5L-4.37114e-08 4.5Z"
                            fill="currentColor"
                          ></path>
                        </svg>
                      </span>
                    </button>
                  </div>
                </form>
                <p className="ajax-response mt-20 text-center"></p>
              </div>
            </div>
          </div>
        </div>
        <div className="tp-ct-map">
          <iframe
            title="map"
           
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3768.4489488694326!2d72.82872168574677!3d19.17558390257042!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7b6f5d4e4f68d%3A0x60b1317f9e73b0f1!2sBanarsi%20Heritage%20Office%2C%20Malad%2C%20Ram%20Nagar%2C%20Malad%20West%2C%20Mumbai%2C%20Maharashtra%20400064!5e0!3m2!1sen!2sin!4v1722515862497!5m2!1sen!2sin" 
            style={{ border: "0" }}
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </div>
    </>
  );
};

export default ContactInfo;
