import React from "react";
import { ServiceItem } from "../../HomeTwo/Services";

const OurService = () => {
  return (
    <>
      <div className="tp-service-ara grey-bg-4 pt-140 pb-110">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="tp-section-wrapper text-center mb-70">
                <span className="tp-section-subtitle mb-30">OUR SERVICES</span>
                <h2 className="tp-section-title">Explore Our Services</h2>
              </div>
            </div>
          </div>
          <div className="row">
            <ServiceItem
              icon="pe-7s-arc"
              title="1-STOP-SHOP for HR Solutions"
              desc="All-in-one HR services for your business"
            />
            <ServiceItem
              icon="pe-7s-cloud-download"
              title="Temporary Staffing"
              desc="Flexible staffing solutions for short-term projects"
            />
            <ServiceItem
              icon="pe-7s-portfolio"
              title="Contract Recruitment"
              desc="Specialized contract staffing for various industries"
            />
            <ServiceItem
              icon="pe-7s-display2"
              title="Employee Assessment"
              desc="Thorough evaluation to ensure the best match"
            />
            <ServiceItem
              icon="pe-7s-cash"
              title="Strong Compliance Framework"
              desc="Ensuring compliance with robust processes"
            />
            <ServiceItem
              icon="pe-7s-global"
              title="PAN India Presence: Ability to handle volumes"
              desc="Nationwide reach for large-scale staffing"
            />
            <ServiceItem
              icon="pe-7s-cup"
              title="Domain expertise across Industry verticals"
              desc="Expert recruitment across various industries"
            />
            <ServiceItem
              icon="pe-7s-graph3"
              title="Adherence to TAT and Quality"
              desc="Timely delivery with top-notch quality"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default OurService;
