import React from "react";
import { Link } from 'react-router-dom';

const LocationMap = () => {
  return (
    <>
      <div className="tp-footer__area grey-bg-4">
        <div className="tp-footer">
          {/* <!-- footer top --> */}
          <div className="tp-footer__top pt-50 pb-50">
            <div className="container">
              <div className="row align-items-center">
              <div className="col-12 col-md-6">
                      <div className="footer-logo ">
                        <Link to="/" href="#"><img src="/assets/img/promotion/promotion.jpg" alt="" /></Link>
                      </div>
                    </div>
                <div className="col-lg-5 pl-50">
                  <h2 className="tp-section-title ">
                  Labour Law <br></br> Compliance
                  </h2>
                  <p>
                  Intex Logistics India Pvt. Ltd. comlplies with all the latest legal requirements and regulations set by relevant government departments. We make sure that our operations and management are in tandem with all the regulations to ensure our services delivered to our clients without any interruption.
                  </p>
                  <p>
                  •> Ministry of Corporate Affairs <br></br>
                  •> Tax Registrations <br></br>

                  &nbsp; &nbsp; - Income Tax - PAN <br></br>
                  &nbsp; &nbsp; - Income Tax - TAN <br></br>
                  &nbsp; &nbsp; - Goods and Services Tax <br></br>
                  &nbsp; &nbsp; - Professional Tax / labour Welfare Fund <br></br>

                    •> Shop and Establishment Registration <br></br>
                    •>Employee Provident Fund Registration <br></br>
                    •>Employee State Insurance Registration <br></br>
                    •>Labour License <br></br>
                    •>Work Contract Registration <br></br>

                  </p>

                  <p>In case your organization requires any further statutory compliance, we will be more than eager to complete the same to your satisfaction.
</p>
                </div>
                
                
              </div>
            </div>
          </div>
          {/* <!-- footer top end  --> */}
        </div>
      </div>
    </>
  );
};

export default LocationMap;
